import { createStore } from "vuex";

export default createStore({
  state: {
    products: [
      {
        name: "cake",
        title: "Товари з холодильних полок магазину: десерти, сирні вироби",
        position: 1,
        maxDays: false,
        minDays: false,
        imgUrl: "https://liebherr.korrespondent.net/products/cake.png",
        description: [
          {
            text: "У звичайному холодильнику: ризик підмороження або повільного охолодження",
            normal: true,
          },
          {
            text: "В холодильнику Liebherr з технологією PowerCooling: рівномірне охолодження",
            normal: true,
          },
          {
            text: "<span class='blue'>Беручи товари з холодної полки магазину, треба не пізніше 2-х годин покласти їх до холодильника, щоб запобігти псуванню.</span>",
          },
          {
            text: "В холодильниках з системою PowerCooling по всій камері підтримується рівномірна температура, а вбудований вентилятор відповідає за швидке охолодження продуктів.",
          },
          {
            text: "<span class='blue'>Додаткову надійність зберігання забезпечує технологія віддаленого управління SmartDevice. Вона дозволяє активовувати функцію SuperCool за допомогою смартфона.</span>Уявіть, ви купуєте свіжі продукти в магазині, а холодильник, за вашою командою, вже готовий їх охолодити.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження продуктів такого роду – холодильник Liebherr з технологією PowerCooling.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "lemon",
        title: "Лимон",
        position: 2,
        maxDays: "4",
        minDays: "2-3",
        imgUrl: "https://liebherr.korrespondent.net/products/lemon.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 2-3 тижні та існує ризик поглинання лимоном сторонніх запахів.</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з вентилятором PowerCooling та повітряним фільтром FreshAir: 4 тижні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+2 тижні</span>",
            normal: true,
          },
          {
            text: "Навіть надрізаний лимон в моделях холодильників, де забезпечена оптимальна якість повітря, не буде поглинати сторонніх запахів.",
          },
          {
            text: "<span class='blue'>Найкращий спосіб зберегти лимони – нарізати їх у скляну банку.</span>",
          },
          {
            text: "Лимони можуть залишатися в холодильнику при температурі 7°С протягом місяця.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження лимонів – холодильник Liebherr з вентилятором PowerCooling та повітряним фільтром FreshAir.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "сheese",
        title: "Твердий сир",
        position: 3,
        maxDays: 110,
        minDays: 10,
        imgUrl: "https://liebherr.korrespondent.net/products/сheese.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 10 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 110 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+100 днів</span>",
            normal: true,
          },
          {
            text: "Тверді та напівтверді розфасовані сири зберігаються 10-15 днів. Подовжити термін зберігання можна, загорнувши сир у харчову плівку або пергаментний папір.",
          },
          {
            text: "Сир – продукт, що вбирає запахи, тому <span class='blue'>після відкриття упаковки його краще зберігати в герметичному контейнері або в пергаментному папері</span> – він пропускає достатню кількість світла і повітря. Розмір пергаменту має бути в 2 рази більший, ніж шматок сиру.",
          },
          {
            text: "До речі, різні види сирів мають різний термін зберігання у звичайному холодильнику. Так, плавлений – до 3 місяців, м'який вершковий – до 30 днів, так званий «ковбасний» – до 2 місяців, розсольні сири – 30-75 днів, м'який з цвіллю – до 7 днів.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження сирів – холодильник Liebherr, зона BioFresh, контейнер Meat & DairySafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "caviar",
        title: "Червона ікра",
        position: 4,
        maxDays: 6,
        minDays: 2,
        imgUrl: "https://liebherr.korrespondent.net/products/caviar.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 2 дні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 6 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+4 дні</span>",
            normal: true,
          },
          {
            text: "Червона ікра продається у бляшаній тарі, але в ній не можна залишати відкритий продукт. Адже під впливом повітря метал швидко окислюється, що негативно позначається на його якості.",
          },
          {
            text: "<span class='blue'>Відразу після відкриття необхідно перекласти ікру в іншу ємність.</span> Ідеально підійде скляна баночка з герметичною кришкою. Для цього можна використовувати і пластиковий контейнер, але слід переконатися, що він не має сторонніх запахів.",
          },
          {
            text: "У жодному разі не можна багаторазово заморожувати і розморожувати ікру – продукт стає небезпечним для вживання.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження червоної ікри – холодильник Liebherr, зона BioFresh, контейнер Meat & DairySafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "milk",
        title: "Молоко",
        position: 5,
        maxDays: 12,
        minDays: 3,
        imgUrl: "https://liebherr.korrespondent.net/products/milk.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 3 дні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 12 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+9 днів</span>",
            normal: true,
          },
          {
            text: "Пастеризоване молоко у ємності, що була відкрита, в звичайному холодильнику з третього дня починає втрачати свої поживні цінності та смакові властивості. Більше 5 діб зберігати його відкритим не можна.",
          },
          {
            text: "Відкрите молоко краще перелити в емальовану або скляну ємність з герметичною кришкою, так воно залишається свіжим довше, ніж у пакеті.",
          },
          {
            text: "<span class='blue'>Дверцята звичайного холодильника – це не краще місце для зберігання молока:</span> продукт вимагає нижчої температури.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження молока – холодильник Liebherr, зона BioFresh, контейнер Meat & DairySafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "eggs",
        title: "Курячі яйця",
        position: 6,
        maxDays: 45,
        minDays: 15,
        imgUrl: "https://liebherr.korrespondent.net/products/eggs.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 15 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>В зоні BioFresh холодильника Liebherr: 45 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+30 днів</span>",
            normal: true,
          },
          {
            text: "Миті та домашні яйця зберігаються довше.",
          },
          {
            text: "<span class='blue'>Краще зберігати яйця вістрями донизу.</span> Тоді жовток буде знаходитися в центрі і не торкатиметься повітряного шару, що розташований у тупого кінця.",
          },
          {
            text: "Яйця з тріснутою шкаралупою краще використовувати відразу або заморозити.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого зберігання яєць – холодильник Liebherr, зона BioFresh, контейнер Meat & DairySafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "salad",
        title: "Готові салати, закуски, напої",
        position: 7,
        maxDays: false,
        minDays: false,
        imgUrl: "https://liebherr.korrespondent.net/products/salad.png",
        description: [
          {
            text: "В звичайному холодильнику є ризик занадто повільного охолодження.",
            normal: true,
          },
          {
            text: "В холодильнику Liebherr з режимом SuperCool - ідеальне охолодження.",
            normal: true,
          },
          {
            text: "SuperCool – функція, що дозволяє автоматично знижувати температуру холодильної камери до + 2°C, + 3°C або + 4°C на 6 або 12 годин в залежності від моделі холодильника.",
          },
          {
            text: "В таких умовах продукти швидко охолоджуються, <span class='blue'>виключається ризик підмороження</span>, що властивий деяким звичайним холодильникам. ",
          },
          {
            text: "Смак і консистенція страв зберігаються.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження готових салатів, закусок та напоїв – холодильник Liebherr з режимом SuperCool</span>",
            normal: true,
          },
        ],
      },
      {
        name: "pan",
        title: "Борщ",
        position: 8,
        maxDays: false,
        minDays: false,
        imgUrl: "https://liebherr.korrespondent.net/products/pan.png",
        description: [
          {
            text: "У звичайному холодильнику: прискорене псування в моделях з нестабільним температурним фоном.",
            normal: true,
          },
          {
            text: "В холодильнику Liebherr з режимом SuperCool: швидке охолодження і комфортне зберігання.",
            normal: true,
          },
          {
            text: "Температура в холодильнику не повинна перевищувати 5°C.",
          },
          {
            text: "<span class='blue'>Гарячу страву не можна ставити в холодильник, краще охолодити її до кімнатної температури.</span>",
          },
          {
            text: "Страву теплу або кімнатної температури накрийте герметичною кришкою, таким чином, зменшиться утворення конденсату та льоду у внутрішній камері холодильника.",
          },
          {
            text: "Заздалегідь активуйте режим SuperCool в холодильнику Liebherr, він допоможе зберегти температуру всередині холодильника на заданому рівні.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження готового борщу – холодильник Liebherr з режимом SuperCool</span>",
            normal: true,
          },
        ],
      },
      {
        name: "meat",
        title: "М'ясо",
        position: 9,
        maxDays: 6,
        minDays: 3,
        imgUrl: "https://liebherr.korrespondent.net/products/meat.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 3 дні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 6 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+3 дні</span>",
            normal: true,
          },
          {
            text: "Охолоджене м'ясо потрібно досить щільно загорнути в пергамент перед розміщенням в холодильнику. Так воно буде «дихати» і збереже свіжість довше.",
          },
          {
            text: "<span class='blue'>Термін зберігання можна продовжити, відокремивши м'ясо від кістки</span> – таким чином, воно може зберігатися на день-два довше.",
          },
          {
            text: "   Оптимальний термін зберігання охолодженого м'яса у звичайному холодильнику – до 3 днів.  Якщо м'ясо пролежало довше, потрібно його терміново приготувати або заморозити.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження м'яса – холодильник Liebherr, зона BioFresh, контейнер Meat & DairySafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "mushrooms",
        title: "Гриби",
        position: 10,
        maxDays: 7,
        minDays: 3,
        imgUrl: "https://liebherr.korrespondent.net/products/mushrooms.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 3 дні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 7 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+4 дні</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У поліетиленовому пакеті зберігати свіжі гриби не рекомендується, бо без повітря там починає утворюватися цвіль.</span>",
          },
          {
            text: "Свіжозібрані гриби треба вимити, просушити, переложити в паперовий пакет або металевий посуд з емальованими стінками та накрити папером чи бавовняною тканиною. І після всіх цих маніпуляцій у звичайному холодильнику вони можуть зберігатися не більше 3 днів. Гриби з магазину у вакуумній упаковці зберігатимуться в звичайному холодильнику 5-7 днів.",
          },
          {
            text: "Цей продукт здатний, як губка, вбирати в себе сторонні запахи. Тому варто зберігати гриби подалі від прянощів, спецій, цибулі та часнику.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження грибів – холодильник Liebherr, зона BioFresh, контейнер Fruit & VegetableSafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "beet",
        title: "Буряк",
        position: 11,
        maxDays: 18,
        minDays: 6,
        imgUrl: "https://liebherr.korrespondent.net/products/beet.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 6 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 18 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+12 днів</span>",
            normal: true,
          },
          {
            text: "Найбільш витриваліший для зберігання – буряк середнього розміру з діаметром в найширшій частині коренеплоду від 10 до 15 сантиметрів. Такі коренеплоди менше страждають від грибкових хвороб і не так швидко в'януть, як малі овочі.",
          },
          {
            text: "Зберігають буряк очищеним від бруду. Не митим, але з доступом до повітря.",
          },
          {
            text: "<span class='blue'>Найкраще буряк зберігається при високій вологості до 90% і температурі повітря + 2⁰С</span>",
          },
          {
            text: "З-поміж інших найпопулярніший спосіб зберігання буряка в звичайному холодильнику – загорнутим в харчову плівку або пакет з отворами. Однак, необхідно стежити, щоб усередині пакета не збирався конденсат.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження буряку – холодильник Liebherr, зона BioFresh, контейнер Fruit & VegetableSafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "carrot",
        title: "Морква",
        position: 12,
        maxDays: 80,
        minDays: 50,
        imgUrl: "https://liebherr.korrespondent.net/products/carrot.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 50 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 80 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+30 днів</span>",
            normal: true,
          },
          {
            text: "Базарну моркву потрібно очистити від грунту і запакувати в поліетиленові вакуумні мішечки. При цьому коренеплоди бажано не мити.",
          },
          {
            text: "Вимиту магазинну моркву довго зберігати не можна, максимум 1-2 тижні.",
          },
          {
            text: "<span class='blue'>Важливо дотримуватися режиму температури і вологості. Зміна температури навіть на 2 градуси може порушити «біологічний спокій» моркви, і вона почне проростати, в'янути та хворіти.</span> Тому при зберіганні моркви у звичайному холодильнику її слід час від часу продивлятися, щоб вилучити зіпсовані коренеплоди.",
          },
          {
            text: "При відправленні моркви на зберігання, вона має бути сухою. А згодом при виявленні конденсату моркву слід витягти, просушити і запакувати повторно.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збеження моркви – холодильник Liebherr, зона BioFresh, контейнер Fruit & VegetableSafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "apple",
        title: "Яблука",
        position: 13,
        maxDays: 80,
        minDays: 40,
        imgUrl: "https://liebherr.korrespondent.net/products/apple.png",
        description: [
          {
            text: "<span class='blue'>У звичайному холодильнику: 40 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>У холодильнику Liebherr з зоною свіжості BioFresh: 80 днів</span>",
            normal: true,
          },
          {
            text: "<span class='blue'>+40 днів</span>",
            normal: true,
          },
          {
            text: "Здатність яблук зберігати свої корисні властивості в холодильнику залежить від сорту. Літні сорти можуть пролежати в звичайному холодильнику від 2 до 4 тижнів. Осінні яблука зберігаються 1-2 місяці. А зимові сорти довше всіх не втрачають своїх первісних якостей: 4-6 місяців при температурі + 3-5 градусів.",
          },
          {
            text: "<span class='blue'>Для яблук важлива достатня вологість повітря, оптимальна – 90-95%. При недостатній вологості плоди зів'януть і зморщаться, а при підвищеній – почнуть швидко гнити.</span>",
          },
          {
            text: "Важлива й вентиляція: якщо яблука лежать в закритому просторі довгий час, вони виділяють газ етилен, що значно знижує термін зберігання фруктів. Тому в звичайному холодильнику камеру з яблуками потрібно кожен день провітрювати.",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження яблук – холодильник Liebherr, зона BioFresh, контейнер Fruit & VegetableSafe.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "dumplings",
        title: "Пельмені, напівфабрикати, морозиво",
        position: 14,
        maxDays: false,
        minDays: false,
        imgUrl: "https://liebherr.korrespondent.net/products/dumplings.png",
        description: [
          {
            text: "У звичайному холодильнику: під час тривалої відпустки, продукти треба викидати або не вимикати холодильник",
            normal: true,
          },
          {
            text: "<span class='blue'>В холодильнику Liebherr з режимом DuoCooling: можна залишити працювати тільки морозильну камеру.</span>",
            normal: true,
          },
          {
            text: "Використання в холодильнику двох незалежних один від одного холодильних контурів дає можливість вільно регулювати температуру в холодильній і морозильній камері.",
          },
          {
            text: "<span class='blue'>Змішування запахів між камерами повністю відсутнє. </span>",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження навівфабрикатів, пельменей, морозива – холодильник Liebherr з режимом DuoCooling.</span>",
            normal: true,
          },
        ],
      },
      {
        name: "berries",
        title: "Ягоди, гриби, фрукти, овочі, зелень (заморожування)",
        position: 15,
        maxDays: null,
        minDays: null,
        imgUrl: "https://liebherr.korrespondent.net/products/berries.png",
        description: [
          {
            text: "У звичайному холодильнику: ризик злипання плодів між собою, нерівномірне замороження, непривабливий вигляд",
            normal: true,
          },
          {
            text: "В холодильнику Liebherr з режимом SuperFrost: глибока і рівномірна заморозка",
            normal: true,
          },
          {
            text: "<span class='blue'>Овочі та фрукти перед заморожуванням треба ретельно вимити і висушити. Якщо цього не зробити - все злипнеться одним каменем. </span> ",
          },
          {
            text: "<span class='blue'>Ідеальне заморожування – при температурах -28 ... -38°С. Це дозволяє якнайкраще зберегти якість продуктів, адже вони заморожуються глибоко і рівномірно.</span> ",
          },
          {
            text: "Глибока та швидка заморозка позбавляє від таких неприємностей як крижана кірка на плодах та примерзання до стінок і полиць. <span class='blue'>Продукти не втрачають властивих їм смакових і поживних якостей.</span> ",
          },
          {
            text: "<span class='blue'>Оптимальні умови для тривалого збереження заморожених ягід, плодів, грибів, зелені – холодильник Liebherr з функцією SuperFrost.  </span> ",
          },
          {
            text: "<span class='blue'>Корисний гаджет 1: </span>акумулятори холоду. Вони допомагають зберегти необхідну температуру в морозильній камері при короткочасному відключенні електроживлення. ",
            normal: true,
          },
          {
            text: "<span class='blue'>Корисний гаджет 2: </span> піднос. З ним можна зручно розподілити продукти без ризику примерзання один до одного",
            normal: true,
          },
        ],
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    getProduct: (state) => {
      return state.products;
    },
  },
  modules: {},
});
